import React, { useState } from "react";
import "./index.css";
import Icon1 from "./img/octicon_person-24.png";
import Icon2 from "./img/carbon_email.png";
import Icon4 from "./img/hugeicons_call.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./login";
import axios from "axios";

function Index() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [activeTab, setActiveTab] = useState("login");

  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const checkEmailExists = async (email) => {
    try {
      const response = await axios.get(
        `http://98.81.204.247/api/users/company?email=${email}`
      );
      console.log(response.data.exists);
      return response.data.exists;
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, "First name must be at least 2 characters")
        .max(50, "First name must be 50 characters or less")
        .required("First name is required"),
      lastName: Yup.string()
        .min(2, "Last name must be at least 2 characters")
        .max(50, "Last name must be 50 characters or less")
        .required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .matches(/^\S*$/, "Email should not contain spaces")
        .matches(
          /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/,
          "Email must contain one '@', a dot ('.'), and at least two letters after the dot"
        )
        .test(
          "single-at-sign",
          "Email should not contain multiple '@' characters",
          (value) => (value.match(/@/g) || []).length <= 1
        )
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Mobile number is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      setEmailExists(false);
      try {
        console.log(values);
        const emailExists = await checkEmailExists(values.email);
        if (emailExists) {
          setEmailExists(true);
          toast.error("Email already exists. Please use a different email.");
          setIsLoading(false);
          return; // Ensure the function exits here if email exists
        }
        const response = await axios.post(
          "http://98.81.204.247/api/students/register",
          values
        );

        // Check if the response indicates the email is already registered
        if (response.data.data === "Email is already registered") {
          toast.error(
            "Email is already registered. Please use a different email."
          );
          setIsLoading(false);
          return; // Exit the function if email is already registered
        }

        // Check if phone number exists
        // const phoneResponse = await axios.get(
        //   `http://98.81.204.247/api/?phone=${values.phone}`
        // );
        // console.log("Phone response:", phoneResponse);

        // if (phoneResponse.data.phone === "Phone number is already registered") {
        //   toast.error(
        //     "Phone number is already registered. Please use a different phone number."
        //   );
        //   setIsLoading(false);
        //   return; // Exit the function if phone number matches
        // }

        console.log(response.data.data);
        toast.success(response.data.data);
        setEmail(values.email);
        setIsSubmitted(true);
        resetForm();
      } catch (error) {
        toast.error("There was an error submitting the form!");
        console.log("There was an error submitting the form!", error);

        if (error.response.data && error.response.status === 409) {
          toast.error("Email already exists. Please use a different email.");
        } else if (
          error.response.data &&
          error.response.data.data === "Email is already registered"
        ) {
          toast.error(
            "Email is already registered. Please use a different email."
          );
        } else {
          const errorMessage =
            error.response?.data?.message ||
            "Error submitting form. Please try again.";
          toast.error(errorMessage);
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const handleOtpSubmit = async () => {
    // Handle OTP submission
    console.log("OTP Submitted:", otp);
    const valuesOtp = {
      email: email,
      otp: otp,
    };
    console.log("Values to be sent:", valuesOtp);
    setIsLoading(true);
    try {
      const response = await axios.post(
        `http://98.81.204.247/api/students/verify`,
        valuesOtp
      );
      console.log(response.data.data);
      toast.success(response.data.data);
      setIsSubmitted(false);
      setOtp("");
    } catch (error) {
      console.error("Error response:", error.response);
      console.log(error.response.data?.message, "Invalid OTP");
      toast.error(error.response.data?.message || "An error occurred");
      setOtp("");
    } finally {
      setIsLoading(false);
      setOtp("");
    }
  };

  return (
    <React.Fragment>
      <section id="AboutHero" className="AboutHero">
        <div className="container about-hero px-0 py-5">
          <div className="row no-gutters pb-5">
            <div className="col-lg-6 col-md-12 col-sm-12 left-section pt-5">
              <h1 className="px-4 hdl-ac">
                Empower Your Team with Interns from Ownprep
              </h1>
              <p className="pt-3 px-4 sub-ac">
                Connect with Skilled Students Ready to Contribute and Innovate
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 right-section pt-5">
              <div className="form-container mx-4  px-5">
                <div className="tab-buttons">
                  <button
                    type="button"
                    className={`btn  switch-button ${
                      activeTab === "login" ? "btn-info" : "btn-light"
                    }`}
                    onClick={() => handleTabClick("login")}
                  >
                    Login
                  </button>
                  <button
                    type="button"
                    className={`btn  switch-button ${
                      activeTab === "register" ? "btn-info" : "btn-light"
                    }`}
                    onClick={() => handleTabClick("register")}
                  >
                    Register
                  </button>
                </div>

                {activeTab === "login" && <Login />}
                {activeTab === "register" && (
                  <div className="mt-4">
                    <>
                      {!isSubmitted ? (
                        <>
                          {/* <p className="py-2 form-headline">
                            Provide your contact information below. We'll get in
                            contact
                          </p> */}
                          <form onSubmit={formik.handleSubmit}>
                            <div className="mb-4 input-group input-group-border">
                              <span className="input-group-text">
                                <img
                                  src={Icon1}
                                  alt="Mobile Icon"
                                  className="icon-img"
                                />
                              </span>
                              <input
                                type="text"
                                className="form-control no-border"
                                id="firstName"
                                placeholder="Enter your first name"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                {...formik.getFieldProps("firstName")}
                              />
                            </div>
                            {formik.touched.firstName &&
                            formik.errors.firstName ? (
                              <div
                                className="error mx-3 text-danger"
                                style={{
                                  marginTop: "-20px",
                                  marginBottom: "5px",
                                }}
                              >
                                {formik.errors.firstName}
                              </div>
                            ) : null}
                            <div className="mb-4 input-group input-group-border">
                              <span className="input-group-text">
                                <img
                                  src={Icon1}
                                  alt="last Name Icon"
                                  className="icon-img"
                                />
                              </span>
                              <input
                                type="text"
                                className="form-control no-border"
                                id="lastName"
                                placeholder="Enter your last name"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                {...formik.getFieldProps("lastName")}
                              />
                            </div>
                            {formik.touched.lastName &&
                            formik.errors.lastName ? (
                              <div
                                className="error mx-3 text-danger"
                                style={{
                                  marginTop: "-20px",
                                  marginBottom: "5px",
                                }}
                              >
                                {formik.errors.lastName}
                              </div>
                            ) : null}
                            <div className="mb-4 input-group input-group-border">
                              <span className="input-group-text">
                                <img
                                  src={Icon2}
                                  alt="Email Icon"
                                  className="icon-img"
                                />
                              </span>
                              <input
                                type="email"
                                className="form-control no-border"
                                id="email"
                                placeholder="Enter your email address"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                {...formik.getFieldProps("email")}
                              />
                            </div>
                            {formik.touched.email && formik.errors.email ? (
                              <div
                                className="error mx-3 text-danger"
                                style={{
                                  marginTop: "-20px",
                                  marginBottom: "5px",
                                }}
                              >
                                {formik.errors.email}
                              </div>
                            ) : null}
                            {emailExists && (
                              <div className="error mx-3 mt-0 text-danger">
                                Email already exists
                              </div>
                            )}
                            <div className="mb-1 input-group input-group-border">
                              <span className="input-group-text">
                                <img
                                  src={Icon4}
                                  alt="Mobile Icon"
                                  className="icon-img"
                                />
                              </span>
                              <input
                                type="tel"
                                className="form-control no-border"
                                id="phone"
                                maxLength="10"
                                placeholder="Enter your mobile number"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                {...formik.getFieldProps("phone")}
                              />
                            </div>
                            {formik.touched.phone && formik.errors.phone ? (
                              <div className="error mx-3 text-danger">
                                {formik.errors.phone}
                              </div>
                            ) : null}
                            <div className="px-4">
                              <button
                                type="submit"
                                className="btn btn-connect full-width-btn"
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  <>
                                    <span>Lets connect</span>{" "}
                                    <i className="bi bi-chevron-compact-right"></i>
                                  </>
                                )}
                              </button>
                            </div>
                          </form>
                        </>
                      ) : (
                        <div className="otp-validation">
                          <div className="otp-title">
                            <h5>Enter your OTP Code</h5>
                          </div>
                          <div className="otp-input-container">
                            <OtpInput
                              className="otp"
                              value={otp}
                              onChange={handleOtpChange}
                              numInputs={4}
                              renderSeparator={<span>-</span>}
                              renderInput={(props, index) => (
                                <input
                                  {...props}
                                  autoFocus={index === 0}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                    margin: "0 5px",
                                    border: "2px solid #2D2F80",
                                    borderRadius: "5px",
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="px-4">
                            <button
                              type="button"
                              className="btn btn-validate full-width-btn mb-2"
                              onClick={handleOtpSubmit}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <span>Loading...</span>
                              ) : (
                                <span>Verify</span>
                              )}
                            </button>
                            {/* <button
                              className="btn btn-validate full-width-btn"
                              onClick={() => {
                                setIsSubmitted(false);
                                setOtp(""); 
                              }}
                            >
                              Back
                            </button> */}
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Index;
