import React from "react";
import Joblist from "../../components/JobListing";

function Joblisting() {
  return (
    <>
      <Joblist />
    </>
  );
}

export default Joblisting;
