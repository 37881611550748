/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./index.css";
import { Dropdown, FormControl, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { LuMapPin } from "react-icons/lu";
import { GiSquare } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import nodataimg from "../../assets/nodataimg.png";
import { useDispatch } from "react-redux";
import { saveJobListingData } from "../../store/joblistingslice";

function Joblist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseURL = "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [companySearch, setCompanySearch] = useState("");
  const [locationSearch, setLocationSearch] = useState("");
  const [skillsSearch, setSkillsSearch] = useState("");
  const [jobListings, setJobListings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const itemsPerPage = 8;

  const totalPages = Math.ceil(jobListings.length / itemsPerPage);

  const handleCompanySelect = (company) => {
    // console.log("Selected Company:", company);
    setSelectedCompany(
      selectedCompany && selectedCompany._id === company._id ? null : company
    );
    // console.log("Updated Selected Company:", selectedCompany);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(
      selectedLocation && selectedLocation._id === location._id
        ? null
        : location
    );
  };

  const handleSkillSelect = (skill) => {
    setSelectedSkill(
      selectedSkill && selectedSkill._id === skill._id ? null : skill
    );
  };

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filteredCompanies = companies.filter((company) => {
    if (typeof company.compname === "string") {
      return company.compname
        .toLowerCase()
        .includes(companySearch.toLowerCase());
    } else {
      console.error("company.compname is not a string:", company);
      return false;
    }
  });

  const filteredLocations = locations.filter((location) => {
    if (typeof location.cityName === "string") {
      return location.cityName
        .toLowerCase()
        .includes(locationSearch.toLowerCase());
    } else {
      console.error("location.cityName is not a string:", location);
      return false;
    }
  });

  const filteredSkills = skills.filter((skill) => {
    if (typeof skill.skillname === "string") {
      return skill.skillname.toLowerCase().includes(skillsSearch.toLowerCase());
    } else {
      console.error("skill.skillname is not a string:", skill);
      return false;
    }
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [companiesResponse, locationsResponse, skillsResponse] =
        await Promise.all([
          axios.get("http://98.81.204.247/api/companies/getcompanies"),
          axios.get("http://98.81.204.247/api/cities/getCity"),
          axios.get("http://98.81.204.247/api/skills-sets/getskills"),
        ]);

      setCompanies(companiesResponse?.data?.data || []);
      setLocations(locationsResponse?.data?.data || []);
      setSkills(skillsResponse?.data?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getAllCompanies = async (
    page = 1,
    company = "",
    location = "",
    skill = ""
  ) => {
    // console.log("Fetching companies with params:", { page, location, skill, company });
    setIsLoading(true);
    try {
      const response = await axios.get(
        "http://98.81.204.247/api/company-profiles/jobbyfilter",
        {
          params: {
            limit: 8,
            page: page,
            company: company,
            location: location,
            skill: skill,
          },
        }
      );
      console.log("Response data:", response?.data?.data || []);
      setJobListings(response?.data?.data || []);
      dispatch(saveJobListingData(response?.data?.data || []));
    } catch (error) {
      console.log("Error fetching companies:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("useEffect triggered with dependencies:", {
      currentPage,
      selectedCompany,
      selectedLocation,
      selectedSkill,
    });
    getAllCompanies(
      currentPage,
      selectedCompany ? selectedCompany._id : "",
      selectedLocation ? selectedLocation._id : "",
      selectedSkill ? selectedSkill._id : ""
    );
  }, [currentPage, selectedCompany, selectedLocation, selectedSkill]);

  const handleEdit = (job) => {
    console.log(job._id);
    navigate("/aboutjob", { state: { id: job._id } });
  };

  return (
    <>
      <section id="joblist" className="joblist skills">
        <div className="container filter-container">
          <div className="filter-main-div">
            <div className="card filter-card">
              <div className="filter-dropdown-div">
                <div>
                  <i className="bi bi-funnel filter-icon"></i>
                  <span className="filter-text">Filter</span>
                </div>

                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="custom-dropdown-toggle"
                  >
                    <span className="dropdown-text">
                      {selectedCompany
                        ? selectedCompany.compname
                        : "Select Company"}
                    </span>
                    <i className="bi bi-chevron-down"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <FormControl
                      className="w-auto fixed-search no-border-focus"
                      placeholder="Search..."
                      aria-label="Search"
                      onChange={(e) => setCompanySearch(e.target.value)}
                    />
                    {filteredCompanies.map((company, index) => (
                      <Dropdown.Item
                        key={index}
                        className={`custom-dropdown-item ${
                          selectedCompany && selectedCompany._id === company._id
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handleCompanySelect(company)}
                      >
                        {company?.compname}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="custom-dropdown-toggle"
                  >
                    <span className="dropdown-text">
                      {" "}
                      {selectedLocation
                        ? selectedLocation.cityName
                        : "Select Location"}
                    </span>

                    <i className="bi bi-chevron-down"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <FormControl
                      className="w-auto fixed-search no-border-focus"
                      placeholder="Search..."
                      aria-label="Search"
                      onChange={(e) => setLocationSearch(e.target.value)}
                    />
                    {filteredLocations.map((location, index) => (
                      <Dropdown.Item
                        key={index}
                        className={`custom-dropdown-item ${
                          selectedLocation &&
                          selectedLocation._id === location._id
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handleLocationSelect(location)}
                      >
                        {location?.cityName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="custom-dropdown-toggle"
                  >
                    <span className="dropdown-text">
                      {selectedSkill
                        ? selectedSkill.skillname
                        : "Select Skills"}
                    </span>
                    <i className="bi bi-chevron-down"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <FormControl
                      className="w-auto fixed-search no-border-focus"
                      placeholder="Search..."
                      aria-label="Search"
                      onChange={(e) => setSkillsSearch(e.target.value)}
                    />
                    {filteredSkills.map((skill, index) => (
                      <Dropdown.Item
                        key={index}
                        className={`custom-dropdown-item ${
                          selectedSkill && selectedSkill._id === skill._id
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handleSkillSelect(skill)}
                      >
                        {skill?.skillname}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="row skills-card mt-5">
            {isLoading ? (
              <div className="col-12 text-center">
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : jobListings.length === 0 ? (
              <div className="col-12 text-center">
                <img src={nodataimg} alt="No Data" className="nodata-img" />
                <div className="nodata-text d-flex justify-content-center mt-2">
                  <h3>No data found</h3>
                </div>
              </div>
            ) : (
              jobListings.map((job, index) => {
                const logoURL = `${baseURL}${job?.user?.logo?.path}`;
                return (
                  <div
                    key={index}
                    className="col-lg-3 col-md-4 col-sm-12 skills-col-hero"
                  >
                    <div className="card">
                      <div className="avatar">
                        <img src={logoURL} alt={job?.user?.companyName?.compname} />
                      </div>
                      <div className="company-description">
                        <h4 className="company-title">
                          {job?.user?.companyName?.compname}
                        </h4>
                        <h6 className="company-designation">
                          {job?.designation}
                        </h6>
                        <div className="company-destination">
                          <LuMapPin className="icon" />{" "}
                          <p>{job?.location?.cityName}</p>
                        </div>
                        <div className="primary-skills">
                          <GiSquare className="icon" />
                          <p>
                            <span className="skill-text-main">
                              {` Primary Skills: `}
                            </span>

                            {(job?.primary_skill?.length > 0 ||
                              job?.secondary_skill?.length > 0 ||
                              job?.tertiary_skill?.length > 0) &&
                              [
                                ...(job.primary_skill || []),
                                ...(job.secondary_skill || []),
                                ...(job.tertiary_skill || []),
                              ].map((skill, index) => (
                                <span key={index} className="skill-text-span">
                                  {skill?.skillname} ,
                                </span>
                              ))}
                            {/* {job.primary_skill?.map((skill, index) => (
                              <span key={index} className="mx-1">
                                {skill?.skillname},
                              </span>
                            ))} */}
                          </p>
                        </div>
                      </div>
                      <div className="btn-div">
                        {/* <Link to={`/aboutjob/${job._id}`}>
                          <Button className="button">
                            View details <IoIosArrowForward />
                          </Button>
                        </Link> */}
                        <Button
                          className="button"
                          onClick={() => handleEdit(job)}
                        >
                          View details <IoIosArrowForward />
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>

          {jobListings.length > 0 && (
            <div className="pagination pagination-listing">
              <button onClick={handlePrevious} disabled={currentPage === 1}>
                <i className="bi bi-chevron-left"></i>
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handleClick(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                <i className="bi bi-chevron-right"></i>
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Joblist;
