import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  joblisting: null,
};

const joblistingSlice = createSlice({
  name: 'joblisting',
  initialState,
  reducers: {
    saveJobListingData: (state, action) => {
      state.joblisting = action.payload;
    },
  },
});

export const { saveJobListingData } = joblistingSlice.actions;

export default joblistingSlice.reducer;