import React from "react";
import StudentLogin from "../../components/StudentLogin/index";

function index() {
  return (
    <>
     <StudentLogin />
    </>
  );
}

export default index;
