import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
// import img4 from "../../assets/company/capgemini.png";
// import img5 from "../../assets/company/infosys.png";
import { LuMapPin } from "react-icons/lu";
import { GiSquare } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import axios from "axios";
import nodataimg from "../../assets/nodataimg.png";

function Skills() {
  const navigate = useNavigate();
  const baseURL = "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
  const [getLiveJobs, setLiveJobs] = useState([]);
  const [visibleCards] = useState(8);

  // Live Jobs API
  const getAllCompanies = async () => {
    try {
      const response = await axios.get(
        "http://98.81.204.247/api/company-profiles/getAlllivejobs"
      );
      setLiveJobs(response?.data?.data);
      console.log(response?.data?.data, "Live Jobs");
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleEdit = (job) => {
    console.log(job._id);
    navigate("/aboutjob", { state: { id: job._id } });
  };

  return (
    <>
      <section id="skills" className="skills container">
        <div className="title">
          <h2>Your Opportunity Spotlight</h2>
        </div>

        <div className="row skills-card">
          {getLiveJobs.length === 0 ? (
            <div className="col-12 text-center">
              <img src={nodataimg} alt="No Data" className="nodata-img" />
              <div className="nodata-text d-flex justify-content-center mt-2">
                <h3>No data found</h3>
              </div>
            </div>
          ) : (
            getLiveJobs.slice(0, visibleCards).map((job, index) => {
              const logoURL = `${baseURL}${job.user?.logo?.path}`;
              return (
                <div
                  key={index}
                  className="col-lg-3 col-md-4 col-sm-12 skills-col-hero"
                >
                  <div className="card">
                    <div className="avatar">
                      <img src={logoURL} alt={job.user?.companyName?.compname} />
                    </div>
                    <div className="company-description">
                      <h4 className="company-title">{job.user?.companyName?.compname}</h4>
                      <h6 className="company-designation">{job.designation}</h6>
                      <div className="company-destination">
                        <LuMapPin className="icon" />{" "}
                        <p>{job?.location?.cityName}</p>
                      </div>
                      <div className="primary-skills">
                        <GiSquare className="icon" />
                        <p>
                          <span className="skill-text-main">
                            {` Primary Skills: `}
                          </span>

                          {(job?.primary_skill?.length > 0 ||
                            job?.secondary_skill?.length > 0 ||
                            job?.tertiary_skill?.length > 0) &&
                            [
                              ...(job.primary_skill || []),
                              ...(job.secondary_skill || []),
                              ...(job.tertiary_skill || []),
                            ].map((skill, index) => (
                              <span key={index} className="skill-text-span">
                                {skill?.skillname} ,
                              </span>
                            ))}
                          {/* {job.primary_skill?.map((skill, index) => (
                              <span key={index} className="mx-1">
                                {skill?.skillname},
                              </span>
                            ))} */}
                        </p>
                      </div>
                    </div>
                    <div className="btn-div">
                      {/* <Link to="/aboutjob">
                        <Button className="button" onClick={()=>handleEdit(job)}>
                          View details <IoIosArrowForward />
                        </Button>
                      </Link> */}
                      <Button
                        className="button"
                        onClick={() => handleEdit(job)}
                      >
                        View details <IoIosArrowForward />
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <div className="text-center mt-4 mb-4">
          <Link to="/joblisting">
            <Button className="toggle-button">
              View More
              <IoIosArrowForward />
            </Button>
          </Link>
        </div>
      </section>
    </>
  );
}

export default Skills;
