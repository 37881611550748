/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { BiMenu } from "react-icons/bi";
import { HashLink } from "react-router-hash-link";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo/ownprep.png";
import { Button } from "react-bootstrap";
import img from "../../../assets/user.png";
import logowhite from "../../../assets/logo/ownprepwhite.png";
import { useSelector } from "react-redux";

const Header = () => {
  // const [token, setToken] = useState(null);
  const studentData = useSelector((state) => state.user.user);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Home");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // For Profile View
  // useEffect(() => {
  //   const storedToken = localStorage.getItem("authToken");
  //   if (storedToken) {
  //     setToken(storedToken);
  //   }
  // }, []);

  const getToken = () => {
    return localStorage.getItem("authToken");
  };

  const token = getToken();

  useEffect(() => {
    // Update active link based on the current path
    const path = location.pathname;
    const hash = location.hash;
    const link = path + hash;
    setActiveLink(link);
  }, [location]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // For LogOut
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    if (mobileMenuOpen) {
      toggleMobileMenu();
    }
    window.scrollTo(0, 0);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -150;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  window.addEventListener("scroll", function () {
    var header = document.getElementById("header");
    if (window.scrollY > 0) {
      header.classList.add("header-blurred");
    } else {
      header.classList.remove("header-blurred");
    }
  });

  return (
    <>
      <header
        id="header"
        className="fixed-top d-flex align-items-center header-transparent"
      >
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo1">
            <h1>
              <Link
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <img src={logo} alt="OwnPrep" />
              </Link>
            </h1>
          </div>
          {/* <div className="mobile-view-profile">
           
          </div> */}
          <nav
            id="navbar"
            className={`navbar ${mobileMenuOpen ? "mobile-nav-open" : ""}`}
          >
            <ul>
              {mobileMenuOpen && (
                <>
                  <div className="logo">
                    <h1>
                      <Link
                        to="/"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <img src={logowhite} alt="OwnPrep" />
                      </Link>
                    </h1>
                  </div>
                  <hr className="logoline" />
                </>
              )}
              <li>
                <HashLink
                  className={`nav-link scrollto ${
                    activeLink === "/" ? "active" : ""
                  }`}
                  smooth
                  to="/"
                  onClick={() => handleLinkClick("/")}
                >
                  Home
                </HashLink>
              </li>
              <li>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/skillboost" ? "active" : ""
                  }`}
                  smooth
                  to="/skillboost"
                  onClick={() => handleLinkClick("/skillboost")}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Skill Boost
                </Link>
              </li>
              {/* <li>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/course" || activeLink === "/coursedetails/:id" ? "active" : ""
                  }`}
                  to="/course"
                  onClick={() => handleLinkClick("/course")}
                >
                  Courses
                </Link>
              </li> */}
              <li>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/course" ||
                    /^\/coursedetails\/\d+$/.test(activeLink)
                      ? "active"
                      : ""
                  }`}
                  to="/course"
                  onClick={() => handleLinkClick("/course")}
                >
                  Courses
                </Link>
              </li>

              <li>
                {/* <HashLink
                  className={`nav-link scrollto ${
                    activeLink === "/#" ? "active" : ""
                  }`}
                  smooth
                  to="/aboutcompany"
                  onClick={() => handleLinkClick("/#about")}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  About Us
                </HashLink> */}
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/aboutcompany" ? "active" : ""
                  }`}
                  to="/aboutcompany"
                  onClick={() => handleLinkClick("/aboutcompany")}
                >
                  Employer
                </Link>
              </li>
              {!token && (
                <li>
                  <Link
                    className={`nav-link scrollto ${
                      activeLink === "/studentlogin" ? "active" : ""
                    }`}
                    to="/studentlogin"
                    onClick={() => handleLinkClick("/studentlogin")}
                  >
                    Student
                  </Link>
                </li>
              )}
              {/* <li>
                <Link to="/free-bootcamp" className="nav-link">
                  <Button className="button">FREE BOOTCAMP</Button>
                </Link>
              </li> */}

              {/* <li>
                <HashLink
                  className={`nav-link scrollto ${
                    activeLink === "/#testimonials" ? "active" : ""
                  }`}
                  smooth
                  to="/#testimonials"
                  onClick={() => handleLinkClick("/#testimonials")}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Testimonials
                </HashLink>
              </li> */}
              {!mobileMenuOpen && (
                <>
                  <li>
                    <Link to="/studentlogin" className="nav-link">
                      <Button className="button">FREE BOOTCAMP</Button>
                    </Link>
                  </li>

                  {token && (
                    <li className="nav-item dropdown profile-icon-avtar">
                      <a
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded={dropdownOpen}
                        onClick={toggleDropdown}
                      >
                        <img
                          src={studentData?.student?.profilePicture || img}
                          alt="img"
                          className="rounded-circle"
                          width={60}
                          height={60}
                          style={{ objectFit: "cover" }}
                        />
                      </a>
                      <ul
                        className={`dropdown-menu dropdown-menu-end profile-dropdown ${
                          dropdownOpen ? "show" : ""
                        }`}
                        aria-labelledby="navbarDropdown"
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="mx-2"
                        >
                          <img
                            src={studentData?.student?.profilePicture || img}
                            alt="img"
                            className="rounded-circle"
                            width={30}
                            height={30}
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "cover",
                            }}
                          />
                          <div style={{ marginLeft: "10px" }}>
                            <p style={{ margin: 0 }}>
                              {studentData?.student?.firstName}{" "}
                              {studentData?.student?.lastName}
                            </p>
                            <p
                              style={{
                                margin: 0,
                                fontSize: "12px",
                                color: "gray",
                              }}
                            >
                              {studentData?.student?.email}
                            </p>
                          </div>
                        </li>

                        <li className="mx-2">
                          <hr className="dropdown-divider" />
                        </li>

                        <li className="mx-2">
                          <Link to="/studentprofile" className="dropdown-item">
                            Profile
                          </Link>
                        </li>
                        <li className="mx-2">
                          <Link to="/" className="dropdown-item">
                            My Wishlist
                          </Link>
                        </li>
                        <li className="mx-2">
                          <Link to="/" className="dropdown-item">
                            My Application
                          </Link>
                        </li>

                        <li className="mx-2">
                          <a
                            className="dropdown-item logout"
                            onClick={handleLogout}
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}
                </>
              )}
            </ul>
            <BiMenu className="mobile-nav-toggle" onClick={toggleMobileMenu} />
          </nav>

          <div className="profile-icon-header">
            <li>
              <Link to="/studentlogin" className="nav-link">
                <Button className="button">FREE BOOTCAMP</Button>
              </Link>
            </li>

            {token && (
              <li className="nav-item dropdown profile-icon-avtar">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded={dropdownOpen}
                  onClick={toggleDropdown}
                >
                  <img
                    src={studentData?.student?.profilePicture || img}
                    alt="img"
                    className="rounded-circle"
                    width={60}
                    height={60}
                    style={{ objectFit: "cover" }}
                  />
                </a>
                <ul
                  className={`dropdown-menu dropdown-menu-end profile-dropdown ${
                    dropdownOpen ? "show" : ""
                  }`}
                  aria-labelledby="navbarDropdown"
                >
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="mx-2"
                  >
                    <img
                      src={studentData?.student?.profilePicture || img}
                      alt="img"
                      className="rounded-circle"
                      width={30}
                      height={30}
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                      }}
                    />
                    <div style={{ marginLeft: "10px" }}>
                      <p style={{ margin: 0 }}>
                        {studentData?.student?.firstName}{" "}
                        {studentData?.student?.lastName}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        {studentData?.student?.email}
                      </p>
                    </div>
                  </li>

                  <li className="mx-2">
                    <hr className="dropdown-divider" />
                  </li>

                  <li className="mx-2">
                    <Link to="/studentprofile" className="dropdown-item">
                      Profile
                    </Link>
                  </li>

                  <li className="mx-2">
                    <a className="dropdown-item logout" onClick={handleLogout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
