import React, { useEffect, useState } from "react";
import "./index.css";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import img1 from "../../assets/user.png";

function Studentprofile() {
  const [showPersonalForm, setPersonalForm] = useState(false); // Personal Details
  const [showEducationForm, setEducationForm] = useState(false); // Education Details
  const [showSkillsForm, setSkillsForm] = useState(false); // Skills Details
  const [showCertificationForm, setCertificationForm] = useState(false); // Certification Details
  const [showSocialForm, setSocilaForm] = useState(false); // Socila Media Details
  const studentData = useSelector((state) => state.user.user);
  console.log(studentData, "Profile Data");

  // Personal Details
  const openPersonlForm = () => setPersonalForm(true);
  const closePersonlForm = () => setPersonalForm(false);

  // Education Details
  const openPersonlForm1 = () => setEducationForm(true);
  const closePersonlForm1 = () => setEducationForm(false);

  // Skills Details
  const openPersonlForm2 = () => setSkillsForm(true);
  const closePersonlForm2 = () => setSkillsForm(false);

  const [inputValue, setInputValue] = useState("");
  const [skills, setSkills] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddSkill = () => {
    if (inputValue.trim()) {
      setSkills([...skills, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleDeleteSkill = (index) => {
    const newSkills = skills.filter((_, i) => i !== index);
    setSkills(newSkills);
  };

  // Certification Details
  const openPersonlForm3 = () => setCertificationForm(true);
  const closePersonlForm3 = () => setCertificationForm(false);

  // Social Details
  const openPersonlForm4 = () => setSocilaForm(true);
  const closePersonlForm4 = () => setSocilaForm(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section id="student-profile" className="student-profile-sec">
        <div className="container mt-4">
          <div className="row">
            {/* Left Sidebar: Profile Card */}
            <div className="col-md-4">
              <div className="card text-center student-profile-card mb-3 student-profile-card-1">
                <div className="profile-sub">
                  <div className="profile-sub-img">
                    <img
                      src={studentData?.student?.profilePicture || img1}
                      className="card-img-top rounded-circle mx-auto mt-3"
                      alt="img"
                    />
                  </div>
                  <div className="card-body profile-sub-info mb-4">
                    <h5 className="card-title">
                      {studentData?.student?.firstName}{" "}
                      {studentData?.student?.lastName}
                    </h5>
                    <p className="card-text1">
                      <i className="bi bi-envelope mx-1"></i>{" "}
                      <span className="wrap">
                        {studentData?.student?.email}
                      </span>
                    </p>
                    <p className="card-text1">
                      {" "}
                      <i className="bi bi-phone mx-1"></i>
                      {studentData?.student?.phone}
                    </p>
                    <p className="card-text1">
                      {" "}
                      <i className="bi bi-geo-alt mx-1"></i>Nagpur
                    </p>
                  </div>
                </div>
              </div>
              {/* Quick Links */}
              <div className="card quick-links-main mb-4 student-profile-card-1">
                <h6 className="px-3 pt-3">
                  <strong>Quick links</strong>
                </h6>
                <ul className="list-group list-group-flush px-3">
                  <li className="list-group-item">
                    <HashLink to="#profile-overview" className="links">
                      Profile Overview
                    </HashLink>
                  </li>
                  <li className="list-group-item">
                    <HashLink to="#personal-details" className="links">
                      Personal Details
                    </HashLink>
                  </li>
                  <li className="list-group-item">
                    <HashLink to="#education-details" className="links">
                      Education Details
                    </HashLink>
                  </li>
                  <li className="list-group-item">
                    <HashLink to="#skills-details" className="links">
                      Skills
                    </HashLink>
                  </li>
                  {/* <li className="list-group-item"><HashLink to='#profile-overview'></HashLink>Projects</li> */}
                  <li className="list-group-item">
                    <HashLink to="#certification-details" className="links">
                      Certification
                    </HashLink>
                  </li>
                  <li className="list-group-item">
                    <HashLink to="#socialmedia-details" className="links">
                      Social media profile
                    </HashLink>
                  </li>
                  {/* <li className="list-group-item"><HashLink to='#profile-overview'></HashLink>Languages</li> */}
                  {/* <li className="list-group-item"><HashLink to='#profile-overview'></HashLink>Document</li> */}
                </ul>
              </div>
            </div>

            {/* Right Side: Profile Details */}
            <div className="col-md-8">
              {/* Profile Overview */}
              <div
                id="profile-overview"
                className="card card-pro-details mb-4 student-profile-card-2"
              >
                <div className="card-body">
                  <h5 className="card-title">Profile Overview</h5>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum mollis nunc a molestie dictum. Mauris venenatis,
                    felis scelerisque aliquet lacinia, nulla nisi venenatis
                    odio, id blandit mauris ipsum id sapien. Vestibulum
                    malesuada orci sit amet pretium facilisis. In lobortis
                    congue augue, a commodo libero tincidunt scelerisque....
                  </p>
                </div>
              </div>

              {/* Personal Details */}
              <div
                id="personal-details"
                className="card card-pro-details mb-4 student-profile-card-2"
              >
                <div className="card-body">
                  <div className="card-pro-details-title">
                    {" "}
                    <h5 className="card-title">Personal Details</h5>
                    <i
                      class="bi bi-pencil-fill student-details-icon"
                      onClick={openPersonlForm}
                    ></i>
                  </div>
                  <p className="card-text">
                    <div class="row personal-details-row">
                      <div class="col-md-6">
                        <span className="sub-mini-title">Date of Birth</span>
                        <br />
                        <strong className="personal-sub-details">
                          00 Sep 0000
                        </strong>
                      </div>
                      <div class="col-md-6">
                        <span className="sub-mini-title">Gender</span>
                        <br />
                        <strong className="personal-sub-details">Male</strong>
                      </div>
                    </div>
                    <div className="profile-address">
                      <span className="sub-mini-title">Address</span>
                      <br />
                      <strong className="personal-sub-details">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum mollis nunc a molestie dictum...
                      </strong>
                    </div>
                  </p>
                </div>
              </div>

              {/* Education Details */}
              <div
                id="education-details"
                className="card card-pro-details mb-4 student-profile-card-2"
              >
                <div className="card-body">
                  <div className="card-pro-details-title">
                    {" "}
                    <h5 className="card-title">Education Details</h5>
                    <i
                      class="bi bi-patch-plus-fill student-details-icon"
                      onClick={openPersonlForm1}
                    ></i>
                  </div>

                  <p className="card-text">
                    <div className="mb-3">
                      <span className="sub-mini-title">Graduation</span>
                      <br />
                      <strong className="personal-sub-details">
                        Indian Institute of Technology, Bombay (2016-2020) |
                        Full-Time | 8.5 CGPA{" "}
                      </strong>
                    </div>
                    <div className="mb-3">
                      <span className="sub-mini-title"> High School</span>

                      <br />
                      <strong className="personal-sub-details">
                        Delhi Public School, New Delhi (2014-2016) | 90%{" "}
                      </strong>
                    </div>
                    <div className="mb-3">
                      <span className="sub-mini-title">Secondary School</span>
                      <br />
                      <strong className="personal-sub-details">
                        Kendriya Vidyalaya, New Delhi (2012-2014) | 88%
                      </strong>
                    </div>
                  </p>
                </div>
              </div>

              {/* Skills */}
              <div
                id="skills-details"
                className="card card-pro-details mb-4 student-profile-card-2"
              >
                <div className="card-body">
                  <div className="card-pro-details-title">
                    {" "}
                    <h5 className="card-title">Skills</h5>
                    <i
                      class="bi bi-patch-plus-fill student-details-icon"
                      onClick={openPersonlForm2}
                    ></i>
                  </div>
                  <p className="card-text">
                    <div>
                      <span className="sub-mini-title">Technical</span>
                      <br />
                      <ul className="card-unorder-list">
                        <li>
                          <strong className="personal-sub-details">CSS</strong>
                        </li>
                        <li>
                          <strong className="personal-sub-details">Html</strong>
                        </li>
                        <li>
                          <strong className="personal-sub-details">Java</strong>
                        </li>
                        <li>
                          <strong className="personal-sub-details">
                            Javascript
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </p>
                </div>
              </div>

              {/* Projects */}
              {/* <div id="Projects-details" className="card card-pro-details ">
                <div className="card-body">
                  <h5 className="card-title">Projects</h5>
                  <p className="card-text">
                     <span className="sub-mini-title">Title-1</span>
                    <div>
                      <strong className="personal-sub-details">
                        www.project-1.com
                      </strong>
                      <p>
                        Finished | Dec 2023-Feb 2024 <br />
                        <span>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Vestibulum mollis nunc a molestie dictum.
                        </span>
                      </p>
                    </div>
                  </p>
                </div>
              </div> */}

              {/* Certification */}
              <div
                id="certification-details"
                className="card card-pro-details mb-4 student-profile-card-2"
              >
                <div className="card-body">
                  <div className="card-pro-details-title">
                    {" "}
                    <h5 className="card-title">Certification</h5>
                    <i
                      class="bi bi-patch-plus-fill student-details-icon"
                      onClick={openPersonlForm3}
                    ></i>
                  </div>
                  <p className="card-text">
                    <span className="sub-mini-title">Certificate-1</span>
                    <div>
                      <strong className="personal-sub-details">
                        Won Project competition
                      </strong>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum mollis nunc a molestie dictum.
                      </p>
                    </div>
                  </p>
                  <p className="card-text">
                    <span className="sub-mini-title">Certificate-1</span>
                    <div>
                      <strong className="personal-sub-details">
                        Won Project competition
                      </strong>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum mollis nunc a molestie dictum.
                      </p>
                    </div>
                  </p>
                </div>
              </div>

              {/* SocialMedia */}
              <div
                id="socialmedia-details"
                className="card card-pro-details mb-4 student-profile-card-2"
              >
                <div className="card-body">
                  <div className="card-pro-details-title">
                    {" "}
                    <h5 className="card-title">Social media profile</h5>
                    <i
                      class="bi bi-patch-plus-fill student-details-icon"
                      onClick={openPersonlForm4}
                    ></i>
                  </div>
                  <p className="card-text">
                    <span className="sub-mini-title">Linked-in</span>
                    <div>
                      <strong className="personal-sub-details">
                        www.mylinkedinprofile.com
                      </strong>
                    </div>
                  </p>
                  <p className="card-text">
                    <span className="sub-mini-title">Behance</span>
                    <div>
                      <strong className="personal-sub-details">
                        www.mybehanceprofile.com
                      </strong>
                    </div>
                  </p>
                </div>
              </div>

              {/* Languages */}
              {/* <div
                id="languages-details"
                className="card card-pro-details mb-4"
              >
                <div className="card-body">
                  <h5 className="card-title">Languages</h5>
                  <p className="card-text">
                    <div>
                      <ul>
                        <li>
                          <strong className="personal-sub-details">
                            English
                          </strong>
                        </li>
                        <li>
                          <strong className="personal-sub-details">
                            Hindi
                          </strong>
                        </li>
                        <li>
                          <strong className="personal-sub-details">
                            Marathi
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* Modals Form */}
      {/* PersonalDetailsForm */}
      {showPersonalForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Personal Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonlForm}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="dob" className="form-label">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      className="form-control form-modal"
                      id="dob"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label">
                      Gender
                    </label>
                    <select className="form-select" id="gender">
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <textarea
                      className="form-control form-modal"
                      id="address"
                      rows="3"
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary student-profile-btn2"
                  onClick={closePersonlForm}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary student-profile-btn1"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* EducationDetailsForm */}
      {showEducationForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Education Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonlForm1}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  {/* <div className="mb-3">
                    <label htmlFor="postgraduation" className="form-label">
                      Post Graduation
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="postgraduation"
                    />
                  </div> */}
                  <div className="mb-3">
                    <label htmlFor="graduation" className="form-label">
                      Graduation
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="graduation"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="diploma" className="form-label">
                      Diploma
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="diploma"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="twelfth" className="form-label">
                      12th
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="twelfth"
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="tenth" className="form-label">
                      10th
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="tenth"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary student-profile-btn2"
                  onClick={closePersonlForm1}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary student-profile-btn1"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* showSkillsForm */}
      {showSkillsForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Skills Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonlForm2}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="skills" className="form-label">
                      Skills name
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-modal"
                        id="skills"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                      {inputValue && (
                        <i
                          class="bi bi-patch-plus-fill student-details-icon"
                          onClick={handleAddSkill}
                        ></i>
                      )}
                    </div>
                  </div>
                  <div className="skills-list">
                    {skills.map((skill, index) => (
                      <span
                        key={index}
                        className="skill-item mx-1"
                        style={{
                          border: "1px solid #524C71",
                          borderRadius: "10px",
                          padding: "3px",
                        }}
                      >
                        {skill}
                        <i
                          class="bi bi-x student-details-icon"
                          onClick={() => handleDeleteSkill(index)}
                        ></i>
                      </span>
                    ))}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary student-profile-btn2"
                  onClick={closePersonlForm2}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary student-profile-btn1"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* showCertificationForm */}
      {showCertificationForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Certificate Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonlForm3}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="certificatename" className="form-label">
                      Certificate Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="certificatename"
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Certificate Description
                    </label>
                    <textarea
                      className="form-control form-modal"
                      id="description"
                      rows="3"
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary student-profile-btn2"
                  onClick={closePersonlForm3}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary student-profile-btn1"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* showSocialForm */}
      {showSocialForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Social Media Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonlForm4}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="linkedin" className="form-label">
                      LinkedIn
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="linkedin"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="facebook" className="form-label">
                      Facebook
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="facebook"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="twitter" className="form-label">
                      Twitter
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="twitter"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="instagram" className="form-label">
                      Instagram
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="instagram"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary student-profile-btn2"
                  onClick={closePersonlForm4}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary student-profile-btn1"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Studentprofile;
