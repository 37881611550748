/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { IoArrowBackSharp } from "react-icons/io5";
import { LuMapPin } from "react-icons/lu";
import { CiHeart } from "react-icons/ci";
import PlayStore from "../../components/PlayStoreBanner/index";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";

function index() {
  const location = useLocation();
  const id = location?.state?.id;
  const [job, setJobDetails] = useState([]);
  const baseURL = "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (id) {
      console.log(id, "about Job");
    }
  }, [id]);

  const jobDetails = async () => {
    try {
      const response = await axios.get(
        `http://98.81.204.247/api/company-profiles/${id}`
      );
      console.log(response?.data?.data, "Job Details");
      setJobDetails(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    jobDetails();
  }, [id]);

  return (
    <>
      <section key={index} id="aboutjob" className="aboutjob mb-5">
        <div className="card card1">
          <div className="container">
            <div className="card-avatar">
              <div className="avatar">
                <img src={`${baseURL}${job.user?.logo?.path}`} alt={job?.user?.companyName?.compname} />
              </div>
            </div>
            <div className="about-description">
              <div className="info">
                <h4 className="about-description-text-sub">
                  {job?.user?.companyName?.compname}
                </h4>
                <h6 className="about-description-text-sub">
                  {job?.designation}
                </h6>
                <p className="about-description-text-sub">{job?.user?.about}</p>
                <p className="about-description-text-sub">
                  <LuMapPin />
                  <span className="mx-1">{job?.location?.cityName}</span>
                </p>
              </div>
              <div className="info-btn">
                <Button className="button2 mb-2">Apply</Button>
                <br />
                <Button className="button2">
                  <CiHeart className="mx-1 icon" /> Wishlist
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card card2">
            {/* <div className="about-comp mb-2">
              <h4>About Company:</h4>
              <p>{job?.about}</p>
            </div> */}
            <div className="about-profile mb-2">
              <h4>Profile:</h4>
              <p>{`${job?.designation} position. It requires you to work on domestic and International clients and is one of the key roles for which the company looks to hire freshers.`}</p>
            </div>
            <div className="about-eligibility mb-2">
              <h4>Eligibility:</h4>
              <ul>
                {/* {job.qualification.map((item, index) => (
                    <li key={index}>
                      <p>{item.qualification}</p>
                    </li>
                  ))} */}
                <li>
                  <p>{job?.experience}</p>
                </li>
                <li>
                  <p>{job?.qualification}</p>
                </li>
                <li>
                  <p>{job?.emp_type}</p>
                </li>
                <li>
                  <p>{job?.package}</p>
                </li>
              </ul>
            </div>
            <div className="skills-card">
              <div className="card tech-card">
                <h6>Technical Skills:</h6>
                {/* <div className="button-container">
                  <Button className="button">{job?.primaryskills}</Button>
                </div> */}
                <div className="button-container">
                  {/* {job?.primary_skill?.length > 0 &&
                    job.primary_skill.map((skill, index) => (
                      <Button key={index} className="button">
                        {skill?.skillname}
                      </Button>
                    ))} */}
                  {(job?.primary_skill?.length > 0 ||
                    job?.secondary_skill?.length > 0 ||
                    job?.tertiary_skill?.length > 0) &&
                    [
                      ...(job.primary_skill || []),
                      ...(job.secondary_skill || []),
                      ...(job.tertiary_skill || []),
                    ].map((skill, index) => (
                      <Button key={index} className="button">
                        {skill?.skillname}
                      </Button>
                    ))}
                </div>
              </div>
              <div className="card soft-card">
                <h6>Soft Skills:</h6>
                <div className="button-container">
                  {job?.addon_skill?.length > 0 &&
                    job.addon_skill.map((skill, index) => (
                      <Button key={index} className="button">
                        {skill?.skillname}
                      </Button>
                    ))}
                  {/* <Button className="button">Communication</Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PlayStore />
    </>
  );
}

export default index;
