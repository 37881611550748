import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import heroimg from "../../assets/img/homeimg.png";
import { IoIosArrowForward } from "react-icons/io";
// import img1 from "../../assets/company/accenture1.png";
// import img2 from "../../assets/company/amazon1.png";
// import img3 from "../../assets/company/capgemini1.png";
// import img4 from "../../assets/company/facebook1.png";
// import img5 from "../../assets/company/infosys1.png";
// import img6 from "../../assets/company/netflix1.png";
// import Marquee from "react-fast-marquee";

import avatar1 from '../../assets/avatart/avatar1.jpg'
import avatar2 from '../../assets/avatart/avatar2.jpg'
import avatar3 from '../../assets/avatart/avatar3.jpg'
import avatar4 from '../../assets/avatart/avatar4.jpg'
import avatar5 from '../../assets/avatart/avatar5.jpg'

function Hero() {
  return (
    <section id="hero" className="hero section">
      <div className="container">
        <div className="row gy-4">
          <div
            className="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-1 d-flex flex-column justify-content-center"
            data-aos="zoom-out"
          >
            <div className="hero-title">
              <h1>Enhance Your Skills and Get Hired</h1>
              <p>Identify required skills from top companies...</p>
            </div>

            {/* <div className="card hero-card not-marquee">
              <div className="hero-card-img">
                <img src={img1} alt="" />
                <img src={img2} alt="" />
                <img src={img3} alt="" />
              </div>
              <div className="hero-card-img">
                <img src={img4} alt="" />
                <img src={img5} alt="" />
                <img src={img6} alt="" />
              </div>
            </div> */}
            {/* Marquee Visible  on 768px */}
            {/* <div className="card hero-card marquee-visible">
            <Marquee gradient={false} delay={0} speed={30} loop={0} play={true} direction="left">
                <div className="d-flex justify-content-around align-items-center">
                  <img src={img1} alt="" className="mx-4" />
                  <img src={img2} alt="" className="mx-4" />
                  <img src={img3} alt="" className="mx-4" />
                  <img src={img4} alt="" className="mx-4" />
                  <img src={img5} alt="" className="mx-4" />
                  <img src={img6} alt="" className="mx-4" />
                  <img src={img3} alt="" className="mx-4" />
                  <img src={img2} alt="" className="mx-4" />
                  <img src={img1} alt="" className="mx-4" />
                  <img src={img2} alt="" className="mx-4" />
                  <img src={img3} alt="" className="mx-4" />
                </div>
              </Marquee>
            </div> */}
            <div className="d-flex hero-title-btn">
              <Link to="/studentlogin" className="nav-link">
                <Button className="btn-get-started">
                  Start Your Journey <IoIosArrowForward />
                </Button>
              </Link>
            </div>
            <div className="student-enroll">
              <div className="avatar-group">
                <img
                  src={avatar1}
                  alt="Avatar 1"
                  className="avatar"
                  loading="lazy"
                />
                <img
                  src={avatar2}
                  alt="Avatar 2"
                  className="avatar"
                  loading="lazy"
                />
                <img
                  src={avatar3}
                  alt="Avatar 3"
                  className="avatar"
                  loading="lazy"
                />
                <img
                  src={avatar4}
                  alt="Avatar 4"
                  className="avatar"
                  loading="lazy"
                />
                <img
                  src={avatar5}
                  alt="Avatar 5"
                  className="avatar"
                  loading="lazy"
                />
              </div>
              <p className="text">10000+ Student already enrolled...</p>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 order-2 order-lg-2 hero-img custom-hide"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <img src={heroimg} className="img-fluid animated" alt="" loading="lazy"/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
