// import React, { useState } from "react";
// import { Button } from "react-bootstrap";
// import img from "../../assets/company/raknew.png";
// import img1 from "../../assets/company/betasys_ai2.jpeg";
// import img2 from "../../assets/company/realtime.png";
// import img3 from "../../assets/company/jerseyi.png";
// import img4 from "../../assets/company/petrolynk.png";
// import img5 from "../../assets/company/mizybook.png";
// import img6 from "../../assets/company/azlogic.png";
// import img7 from "../../assets/company/wiriya.png";
// // import img4 from "../../assets/company/capgemini.png";
// // import img5 from "../../assets/company/infosys.png";
// import { LuMapPin } from "react-icons/lu";
// import { GiSquare } from "react-icons/gi";
// import { IoIosArrowForward } from "react-icons/io";
// import { Link } from "react-router-dom";
// // import { Swiper, SwiperSlide } from "swiper/react";
// // import { Autoplay } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/autoplay";
// import JobDetails from "../../JobDetails.json";

// function Skills() {
//   console.log(JobDetails, "job");

//   const skills5 = [
//     {
//       img: img,
//       companyTitle: "RAK Softech Pvt Ltd",
//       companyDesignation: "MERN Stack",
//       location: "Nagpur",
//       primarySkills: "JavaScript, Node Js, React Js, Express Js, MongoDb",
//     },
//     {
//       img: img1,
//       companyTitle: "Liquid",
//       companyDesignation: "Data Scientist",
//       location: "New Delhi",
//       primarySkills:
//         "Python, Data Manipulation, Machine Learning, Artificial intellgence, ",
//     },
//     {
//       img: img2,
//       companyTitle: "Real Time Networking",
//       companyDesignation: "Laravel Developer",
//       location: "Bangalore",
//       primarySkills: "PHP, SQL, Laravel, Postgres, PLSql ",
//     },
//     {
//       img: img3,
//       companyTitle: "Jerseyitech",
//       companyDesignation: "AI/ML Developer",
//       location: "Mumbai",
//       primarySkills: "Python, MLOops, Deep Learning, TensorFlow",
//     },
//     {
//       img: img4,
//       companyTitle: "PetroLynks",
//       companyDesignation: "Data Scientist",
//       location: "Noida",
//       primarySkills:
//         "Data Visualization, MongoDb, Excel, critical thinking, and business acumen",
//     },
//     {
//       img: img5,
//       companyTitle: "Mizybook",
//       companyDesignation: "Python Developer",
//       location: "Jaipur",
//       primarySkills: "Python, Python Framework, Django, Flask",
//     },
//     {
//       img: img6,
//       companyTitle: "azLogics",
//       companyDesignation: "UI/UX Developer",
//       location: "Nagpur",
//       primarySkills: "Figma, HTML5, CSS3, JavaScript, Jquery ",
//     },
//     {
//       img: img7,
//       companyTitle: "Wiriya",
//       companyDesignation: "React Nativ Developer",
//       location: "Pune",
//       primarySkills: "JavaScript, ES6, User Interface",
//     },
//     {
//       img: img2,
//       companyTitle: "Real Time Networking",
//       companyDesignation: "Laravel Developer",
//       location: "Bangalore",
//       primarySkills: "PHP, SQL, Laravel, Postgres, PLSql ",
//     },
//     {
//       img: img3,
//       companyTitle: "Jerseyitech",
//       companyDesignation: "AI/ML Developer",
//       location: "Mumbai",
//       primarySkills: "Python, MLOops, Deep Learning, TensorFlow",
//     },
//   ];

//   const [visibleCards, setVisibleCards] = useState(8);
//   const [showMore, setShowMore] = useState(true);
//   const handleToggle = () => {
//     if (showMore) {
//       setVisibleCards((prev) => prev + 8); // Increase number of visible cards
//       if (visibleCards + 8 >= JobDetails.length) {
//         setShowMore(false); // No more cards to show, switch to "Show Less"
//       }
//     } else {
//       setVisibleCards(8); // Reset to show only the initial 8 cards
//       setShowMore(true); // Switch back to "View More"
//     }
//   };

//   return (
//     <>
//       <section id="skills" className="skills container" style={{marginTop:'8rem'}}>
//         <div className="title">
//           <h2>Your Opportunity Spotlight</h2>
//         </div>

//         <div className="row skills-card">
//           {JobDetails.slice(0, visibleCards).map((skill, index) => (
//             <div
//               key={index}
//               className="col-lg-3 col-md-4 col-sm-12 skills-col-hero"
//             >
//               <div className="card">
//                 <div className="avatar">
//                   <img src={skill.imgUrl} alt="Avatar" />
//                 </div>
//                 <div className="company-description">
//                   <h4 className="company-title">{skill.title}</h4>
//                   <h6 className="company-designation">{skill.designation}</h6>
//                   <div className="company-destination">
//                     <LuMapPin className="icon" /> <p>{skill.lacation}</p>
//                   </div>
//                   <div className="primary-skills">
//                     <GiSquare className="icon" />
//                     <p>
//                       Primary skills:
//                       <span className="mx-1">{skill.primaryskills}</span>
//                     </p>
//                   </div>
//                 </div>
//                 <div className="btn-div">
//                   <Link to={`/aboutjob/${skill.id}`}>
//                     <Button className="button">
//                       View details <IoIosArrowForward />
//                     </Button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//         {(visibleCards < skills5.length || !showMore) && (
//           <div className="text-center mt-4 mb-5">
//             <Button onClick={handleToggle} className="toggle-button">
//               {showMore ? "View More" : "Show Less"}
//               <IoIosArrowForward />
//             </Button>
//           </div>
//         )}
//       </section>
//     </>
//   );
// }

// export default Skills;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
// import img4 from "../../assets/company/capgemini.png";
// import img5 from "../../assets/company/infosys.png";
import { LuMapPin } from "react-icons/lu";
import { GiSquare } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import axios from "axios";
import nodataimg from "../../assets/nodataimg.png";

function Skills() {
  const navigate = useNavigate();
  const baseURL = "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
  const [getLiveJobs, setLiveJobs] = useState([]);
  const [visibleCards] = useState(8);

  // Live Jobs API
  const getAllCompanies = async () => {
    try {
      const response = await axios.get(
        "http://98.81.204.247/api/company-profiles/getAlllivejobs"
      );
      setLiveJobs(response?.data?.data);
      console.log(response?.data?.data, "Live Jobs");
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleEdit = (job) => {
    console.log(job._id);
    navigate("/aboutjob", { state: { id: job._id } });
  };

  return (
    <>
      <section id="skills" className="skills container">
        <div className="title mt-5">
          <h2>Your Opportunity Spotlight</h2>
        </div>

        <div className="row skills-card">
          {getLiveJobs.length === 0 ? (
            <div className="col-12 text-center">
              <img src={nodataimg} alt="No Data" className="nodata-img" />
              <div className="nodata-text d-flex justify-content-center mt-2">
                <h3>No data found</h3>
              </div>
            </div>
          ) : (
            getLiveJobs.slice(0, visibleCards).map((job, index) => {
              const logoURL = `${baseURL}${job.user?.logo?.path}`;
              return (
                <div
                  key={index}
                  className="col-lg-3 col-md-4 col-sm-12 skills-col-hero"
                >
                  <div className="card">
                    <div className="avatar">
                      <img src={logoURL} alt={job.user?.companyName?.compname} />
                    </div>
                    <div className="company-description">
                      <h4 className="company-title">{job.user?.companyName?.compname}</h4>
                      <h6 className="company-designation">{job.designation}</h6>
                      <div className="company-destination">
                        <LuMapPin className="icon" />{" "}
                        <p>{job?.location?.cityName}</p>
                      </div>
                      <div className="primary-skills">
                        <GiSquare className="icon" />
                        <p>
                          <span className="skill-text-main">
                            {` Primary Skills: `}
                          </span>

                          {(job?.primary_skill?.length > 0 ||
                            job?.secondary_skill?.length > 0 ||
                            job?.tertiary_skill?.length > 0) &&
                            [
                              ...(job.primary_skill || []),
                              ...(job.secondary_skill || []),
                              ...(job.tertiary_skill || []),
                            ].map((skill, index) => (
                              <span key={index} className="skill-text-span">
                                {skill?.skillname} ,
                              </span>
                            ))}
                          {/* {job.primary_skill?.map((skill, index) => (
                              <span key={index} className="mx-1">
                                {skill?.skillname},
                              </span>
                            ))} */}
                        </p>
                      </div>
                    </div>
                    <div className="btn-div">
                      {/* <Link to="/aboutjob">
                        <Button className="button" onClick={()=>handleEdit(job)}>
                          View details <IoIosArrowForward />
                        </Button>
                      </Link> */}
                      <Button
                        className="button"
                        onClick={() => handleEdit(job)}
                      >
                        View details <IoIosArrowForward />
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <div className="text-center mt-4 mb-4">
          <Link to="/joblisting">
            <Button className="toggle-button">
              View More
              <IoIosArrowForward />
            </Button>
          </Link>
        </div>
      </section>
    </>
  );
}

export default Skills;
