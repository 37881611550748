import React from "react";
import "./index.css";
import playbtn from "../../assets/img/playstore/PlayButton.png";
import mobileimg from "../../assets/img/playstore/playstoreimg.png"

function index() {
  return (
    <>
      <section id="Play-banner" className="Play-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xsm-12 order-lg-1 order-md-2 order-sm-2 order-xsm-2 Play-banner-div">
              <div className="baneer-text">
                <h1>
                  Sharpen Your Skills with Practice Tests on Our Mobile App.
                </h1>
                <p>
                  Prepare for success anytime, anywhere. Download our Android
                  app and start taking practice tests to boost your confidence
                  and readiness.
                </p>
              </div>
              <div className="play-btn">
                <a href="/#">
                  <img src={playbtn} alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xsm-12 order-lg-2 order-md-1 order-sm-1 order-xsm-1">
              <div className="app-img">
                <img src={mobileimg} className="img1" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default index;
