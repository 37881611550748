import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/layouts/Header/index";
import Footer from "./components/layouts/Footer/index";
import Home from "./pages/Home/index";
import AboutJob from "./pages/AboutJob/index";
import AboutComapany from "./pages/AboutCompany/index";
import Course from "./pages/Course/index";
import SkillBoost from "./pages/SkillBoost/index";
import CourseDetails from "./pages/CourseDetails/index";
import FreeBootCamp from "./pages/FreeBootCamp/index";
import EnrollPage from "./pages/EnrollPage/index";
import PrivacyPolicy from "./pages/PrivacyPolicy/index";
import StudentLogin from "./pages/StudentLogin/index";
import Studentprofile from "./pages/StudentProfile";
import Joblisting from "./pages/JobListing";
function App() {
  return (
    <div className="app-container">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutjob" element={<AboutJob />} />
          <Route path="/aboutcompany" element={<AboutComapany />} />
          <Route path="/course" element={<Course />} />
          <Route path="/skillboost" element={<SkillBoost />} />
          <Route path="/free-bootcamp" element={<FreeBootCamp />} />
          <Route path="/coursedetails/:id" element={<CourseDetails />} />
          <Route path="/enrollpage" element={<EnrollPage />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/studentlogin" element={<StudentLogin />} />
          <Route path="/studentprofile" element={<Studentprofile />} />
          <Route path="/joblisting" element={<Joblisting />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
