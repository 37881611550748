/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Icon2 from "./img/email.png";
import Icon3 from "./img/lock.png";
import { useDispatch } from 'react-redux';
import { saveUserData } from '../../store/userslice';
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      navigate("/studentprofile");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Required email address"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Required password"),
    }),
    onSubmit: async (values, { resetForm }) => {
    setIsLoading(true);
      try {
        const response = await axios.post(
          // `${`${import.meta.env.VITE_APP_BASE_URL}/auth/student/login`}`,
          `http://98.81.204.247/api/auth/student/login`,
          {
            email: values.email,
            password: values.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response, "loginData");
        // Extract token from response
        const token = response?.data?.data?.token;
        const student = response?.data?.data;

        if (token) {
          // Store token in localStorage
          localStorage.setItem("authToken", token);
          console.log(response?.data?.data, "LoginData");
          dispatch(saveUserData(student));
          navigate('/studentprofile');
          toast.success("You are successfully logged in");
          resetForm();
          setIsLoading(false);
        } else {
          toast.error("Login failed. Token not received.");
        }
      } catch (error) {
        toast.error("Login failed. Please try again.");
        setIsLoading(false);
      }
    },

    // onSubmit: async (values) => {
    //   setIsLoading(true);
    //   try {
    //     const response = await axios.post(
    //       `http://98.81.204.247/api/auth/student/login`,
    //       {
    //         email: values.email,
    //         password: values.password,
    //       },
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );
    //     console.log(response?.data?.data?.token, "Student Profile");
    //     // Extract token from response
    //     const token = response?.data.token;
    //     // const student = response.data.data;
    //     // Store token in localStorage
    //     localStorage.setItem("authToken", token);
    //     // dispatch(saveUserData(student));
    //     console.log(response.data.data, "loginData");
    //     toast.success(response.data.data);
    //     setIsLoading(false);
    //   } catch (error) {
    //     toast.error("Login failed. Please try again.");
    //     setIsLoading(false);
    //   }
    // },
  });

  return (
    <div className="mt-3">
      <form onSubmit={formik.handleSubmit}>
        {/* <label htmlFor="email">Email address</label> */}
        <div className="input-group input-group-border">
          <span className="input-group-text">
            <img src={Icon2} alt="Email Icon" className="icon-img" />
          </span>
          <input
            type="email"
            className="form-control no-border"
            id="email"
            name="email"
            placeholder="Enter email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
        </div>
        {formik.touched.email && formik.errors.email ? (
          <div className="error text-danger">{formik.errors.email}</div>
        ) : null}
        {/* <label htmlFor="password" className="mt-3">Password</label> */}
        <div className="input-group input-group-border mt-3">
        <span className="input-group-text">
            <img src={Icon3} alt="Email Icon" className="icon-img" />
          </span>
          <input
            type="password"
            className="form-control no-border"
            id="password"
            name="password"
            placeholder="Enter password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
        </div>
        {formik.touched.password && formik.errors.password ? (
          <div className="error text-danger">{formik.errors.password}</div>
        ) : null}
        <button
          type="submit"
          className="btn btn-connect full-width-btn"
          disabled={isLoading}
        >
          {isLoading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <>
              <span>Login</span>
            </>
          )}
        </button>
      </form>
    </div>
  );
}

export default Login;
